var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "page-padding",
    },
    [
      _c(
        "div",
        {
          staticClass: "page-header--wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "page-header-wrap--title",
            },
            [_vm._v("账号管理")]
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAddStaff,
                  },
                },
                [_vm._v(" 新增账号 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-row",
        {
          staticClass: "page-filter--wrap",
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                inline: "",
                "label-width": "auto",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户查询",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "flex",
                    },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.searchKeyname,
                            callback: function callback($$v) {
                              _vm.searchKeyname = $$v
                            },
                            expression: "searchKeyname",
                          },
                        },
                        _vm._l(_vm.searchKeynameList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: {
                              value: item.value,
                              label: item.label,
                            },
                          })
                        }),
                        1
                      ),
                      _c("el-input", {
                        staticClass: "ml-[10px]",
                        attrs: {
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.searchValue,
                          callback: function callback($$v) {
                            _vm.searchValue = $$v
                          },
                          expression: "searchValue",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "margin-left": "20px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        round: "",
                        icon: "el-icon-refresh",
                      },
                      on: {
                        click: _vm.resetFormData,
                      },
                    },
                    [_vm._v(" 重置 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        round: "",
                        icon: "el-icon-search",
                      },
                      on: {
                        click: _vm.handleSearch,
                      },
                    },
                    [_vm._v(" 搜索 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: {
            width: "100%",
          },
          attrs: {
            data: _vm.staffData,
            stripe: "",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                type: "index",
                align: "center",
                width: "60",
              },
            },
            [
              _c(
                "template",
                {
                  slot: "header",
                },
                [_vm._v(" 序号 ")]
              ),
            ],
            2
          ),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "180",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref) {
                  var row = _ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleUpdateStaff(row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "!text-red-500",
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleDeleteStaff(row)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "text",
                        },
                        on: {
                          click: function click($event) {
                            return _vm.handleUpdateAuthority(row)
                          },
                        },
                      },
                      [_vm._v(" 权限 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.tableFormatter,
              align: "center",
              prop: "account_name",
              label: "账号名",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.tableFormatter,
              align: "center",
              prop: "user_name",
              label: "用户名",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.tableFormatter,
              align: "center",
              prop: "phone",
              label: "手机号",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.tableFormatter,
              align: "center",
              prop: "mail",
              label: "邮箱",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "状态",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref2) {
                  var row = _ref2.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: row.status === 1 ? "" : "danger",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(row.status === 1 ? "启用" : "禁用") + " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "角色",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref3) {
                  var row = _ref3.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.roles
                            .map(function (item) {
                              return item.role_name
                            })
                            .join(", ") || "-"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              formatter: _vm.tableFormatter,
              align: "center",
              prop: "update_user",
              label: "创建人/修改人",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref4) {
                  var row = _ref4.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.create_user || "-") +
                        "/" +
                        _vm._s(row.update_user || "-") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "300",
              formatter: _vm.tableFormatter,
              align: "center",
              prop: "update_time",
              label: "创建时间/修改时间",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(_ref5) {
                  var row = _ref5.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(row.create_time || "-") +
                        "/" +
                        _vm._s(row.update_time || "-") +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function updatePage($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function updateLimit($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "".concat(_vm.textMap[_vm.dialogStatus], "\u8D26\u53F7"),
            visible: _vm.dialogStaffForm,
            width: "500px",
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogStaffForm = $event
            },
            opened: _vm.handleDialogOpen,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "staffForm",
              attrs: {
                rules: _vm.staffFormRules,
                model: _vm.staffForm,
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "账号名",
                    prop: "account_name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: _vm.dialogStatus === "update",
                      placeholder: "仅限输入数字和字母",
                    },
                    model: {
                      value: _vm.staffForm.account_name,
                      callback: function callback($$v) {
                        _vm.$set(_vm.staffForm, "account_name", $$v)
                      },
                      expression: "staffForm.account_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户名",
                    prop: "user_name",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.staffForm.user_name,
                      callback: function callback($$v) {
                        _vm.$set(_vm.staffForm, "user_name", $$v)
                      },
                      expression: "staffForm.user_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "邮箱",
                    prop: "mail",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.staffForm.mail,
                      callback: function callback($$v) {
                        _vm.$set(_vm.staffForm, "mail", $$v)
                      },
                      expression: "staffForm.mail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "手机号",
                    prop: "phone",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.staffForm.phone,
                      callback: function callback($$v) {
                        _vm.$set(_vm.staffForm, "phone", $$v)
                      },
                      expression: "staffForm.phone",
                    },
                  }),
                ],
                1
              ),
              _vm.dialogStatus !== "update"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "密码",
                        prop: "password",
                        rules: [
                          {
                            required: _vm.dialogStatus !== "update",
                            message: "请输入密码",
                            trigger: "blur",
                          },
                          {
                            pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/,
                            message: "请输入6-20位字符, 大/小写字母+数字组合",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入6-20位字符, 大/小写字母+数字组合",
                        },
                        model: {
                          value: _vm.staffForm.password,
                          callback: function callback($$v) {
                            _vm.$set(_vm.staffForm, "password", $$v)
                          },
                          expression: "staffForm.password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "状态",
                    prop: "status",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.staffForm.status,
                        callback: function callback($$v) {
                          _vm.$set(_vm.staffForm, "status", $$v)
                        },
                        expression: "staffForm.status",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: 1,
                          },
                        },
                        [_vm._v("启用")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            label: 0,
                          },
                        },
                        [_vm._v("禁用")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogStaffForm = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    keydown: function keydown($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleDialogConfirm.apply(null, arguments)
                    },
                    click: _vm.handleDialogConfirm,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.dialogStatus === "update" ? "修改" : "确定") +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑权限",
            visible: _vm.dialogAuthority,
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogAuthority = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "authorityForm",
              attrs: {
                rules: _vm.authorityFormRules,
                model: _vm.authorityForm,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "店铺权限",
                    prop: "mall_ids",
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.authorityForm.mall_ids,
                        callback: function callback($$v) {
                          _vm.$set(_vm.authorityForm, "mall_ids", $$v)
                        },
                        expression: "authorityForm.mall_ids",
                      },
                    },
                    _vm._l(_vm.shopList, function (item) {
                      return _c(
                        "el-checkbox",
                        {
                          key: item.id,
                          attrs: {
                            label: item.id,
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色",
                    prop: "role_ids",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择角色",
                        multiple: "",
                      },
                      model: {
                        value: _vm.authorityForm.role_ids,
                        callback: function callback($$v) {
                          _vm.$set(_vm.authorityForm, "role_ids", $$v)
                        },
                        expression: "authorityForm.role_ids",
                      },
                    },
                    _vm._l(_vm.roleList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.role_name,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "text",
                  },
                  on: {
                    click: function click($event) {
                      _vm.dialogAuthority = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                  },
                  on: {
                    click: _vm.handleAuthConfirm,
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }