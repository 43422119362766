<template>
  <div class="h-full">
    <div class="login-container">
      <el-form ref="loginFormRef" :rules="loginRules" :model="loginForm" class="login-form" auto-complete="on" label-position="left">
        <div class="title-container">
          <h3 class="title">欢迎登录</h3>
        </div>

        <el-form-item prop="username">
          <span class="svg-container">
            <svg-icon icon-class="user" />
          </span>
          <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="请输入账号名/手机号/邮箱"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
          />
        </el-form-item>

        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="密码"
            name="password"
            tabindex="2"
            auto-complete="on"
            @keyup.enter.native="handleLogin"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
          </span>
        </el-form-item>

        <div class="flex justify-between items-center mb-4">
          <el-checkbox v-model="isRemember" label="记住密码" />
          <el-button type="text" @click="handleForgetPassword">忘记密码</el-button>
        </div>

        <el-button :loading="loading" type="primary" size="large" style="width: 100%; margin-bottom: 30px" @click.native.prevent="handleLogin">
          登录
        </el-button>
      </el-form>
    </div>
    <el-dialog
      center
      class="forget-container"
      :visible="dialogVisible"
      title="找回密码"
      width="500px"
      @opened="handleClearValidate"
      @close="handleCloseDialog"
    >
      <el-steps align-center space="220px" class="mt-[-20px]" :active="stepIndex" finish-status="success">
        <el-step title="获取验证码"></el-step>
        <el-step title="重置密码"></el-step>
      </el-steps>
      <el-form
        ref="forgetPasswordFormRef"
        label-position="top"
        :rules="forgetPasswordRules"
        :model="forgetPasswordForm"
        class="forget-password-form"
        size="large"
      >
        <template v-if="stepIndex === 0">
          <el-form-item prop="email" label="邮箱">
            <el-input v-model="forgetPasswordForm.email" placeholder="邮箱" name="email" type="text" @input="handleChangeEmail" />
          </el-form-item>
          <el-form-item prop="captcha_code" label="验证码">
            <div class="flex justify-between items-center">
              <el-input
                v-model="forgetPasswordForm.captcha_code"
                class="mr-2"
                placeholder="验证码"
                name="captcha_code"
                type="text"
                @input="handleBlurCaptchaCode"
              />
              <el-button :disabled="isSendButtonDisabled || isCountDownActive" type="primary" @click="handleSendCaptcha">{{
                isCountDownActive ? `${countDown}s` : "获取验证码"
              }}</el-button>
            </div>
          </el-form-item>
          <div class="flex justify-between mt-6 mb-[-6px]">
            <el-button type="text" @click="handleCloseDialog">返回登录</el-button>
            <el-button :disabled="isNextButtonDisabled" type="text" @click="handleNextStep">下一步</el-button>
          </div>
        </template>
        <template v-if="stepIndex === 1">
          <el-form-item prop="password" label="新密码">
            <el-input
              :key="passwordTypeForgetPassword.password + 1"
              ref="password"
              v-model="forgetPasswordForm.password"
              placeholder="请输入6-20位字符, 大/小写字母+数字组合"
              name="password"
              :type="passwordTypeForgetPassword.password"
            >
              <template #suffix>
                <span class="show-pwd mr-2" @click="showPwdForgetPassword('password')">
                  <svg-icon :icon-class="passwordTypeForgetPassword.password === 'password' ? 'eye' : 'eye-open'" />
                </span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password_confirm" label="确认密码">
            <el-input
              :key="passwordTypeForgetPassword.password_confirm + 2"
              ref="password_confirm"
              v-model="forgetPasswordForm.password_confirm"
              placeholder="请再次输入密码"
              name="password_confirm"
              :type="passwordTypeForgetPassword.password_confirm"
            >
              <template #suffix>
                <span class="show-pwd mr-2" @click="showPwdForgetPassword('password_confirm')">
                  <svg-icon :icon-class="passwordTypeForgetPassword.password_confirm === 'password' ? 'eye' : 'eye-open'" />
                </span>
              </template>
            </el-input>
          </el-form-item>
          <div class="flex justify-end mt-6 mb-[-6px]">
            <el-button size="medium" type="primary" @click="handleResetPassword">确定</el-button>
          </div>
        </template>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { ref, nextTick, getCurrentInstance, onBeforeMount } from "vue";
import { encrypt, decrypt, loginEncrypt } from "@/utils/js-encrypt";
import { to } from "@/utils/common";
import api from "@/api";
import { omit } from "lodash-es";
import { useIntervalFn } from "@vueuse/shared";

const EMAIL_REG = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

export default {
  name: "LoginIndex",
  setup() {
    const { proxy } = getCurrentInstance();

    const loginForm = ref({
      username: "",
      password: "",
    });
    const isRemember = ref(false);
    const loading = ref(false);
    const passwordType = ref("password");
    const passwordTypeForgetPassword = ref({
      password: "password",
      password_confirm: "password",
    });
    const redirect = ref(undefined);

    const loginRules = {
      username: [{ required: true, trigger: "blur", message: "用户名必填" }],
      password: [
        { required: true, message: "请输入密码", trigger: "blur" },
        // { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/, message: "输入6-20位字符, 大/小写字母+数字组合", trigger: "blur" },
      ],
    };

    function cacheAccountAndPassword() {
      if (isRemember.value) {
        let setItem = JSON.stringify({ loginForm: loginForm.value, isRemember: isRemember.value });
        localStorage.setItem("loginForm", encrypt(setItem));
      } else {
        localStorage.removeItem("loginForm");
      }
    }

    function showPwd() {
      if (passwordType.value === "password") {
        passwordType.value = "";
      } else {
        passwordType.value = "password";
      }
      nextTick(() => {
        proxy.$refs.password.focus();
      });
    }
    function showPwdForgetPassword(key) {
      if (passwordTypeForgetPassword.value[key] === "password") {
        passwordTypeForgetPassword.value[key] = "";
      } else {
        passwordTypeForgetPassword.value[key] = "password";
      }
    }

    async function handleLogin() {
      loading.value = true;
      const [validErr] = await to(proxy.$refs.loginFormRef.validate());
      if (validErr === false) {
        loading.value = false;
        return console.error("login validate fail");
      }
      const [err] = await proxy.$store.dispatch("user/login", {
        username: loginEncrypt(loginForm.value.username),
        password: loginEncrypt(loginForm.value.password),
      });
      if (err) {
        console.error(err);
      } else {
        proxy.$router.push({ path: redirect.value || "/" });
        cacheAccountAndPassword();
      }
      loading.value = false;
    }

    const forgetPasswordFormRef = ref(null);
    const dialogVisible = ref(false);
    const stepIndex = ref(0);
    const forgetPasswordRules = {
      email: [
        { required: true, trigger: "blur", message: "邮箱必填" },
        { pattern: EMAIL_REG, message: "请输入正确的邮箱格式", trigger: "blur" },
      ],
      captcha_code: [{ required: true, trigger: "blur", message: "验证码必填" }, { validator: isCaptchaValid }],
      password: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/, message: "输入6-20位字符, 大/小写字母+数字组合", trigger: "blur" },
      ],
      password_confirm: [
        { required: true, message: "请再次输入密码", trigger: "blur" },
        { validator: isPasswordConfirmValid, trigger: "blur" },
      ],
    };
    const forgetPasswordForm = ref({
      email: "",
      captcha_code: "",
      password: "",
      password_confirm: "",
    });
    function isPasswordConfirmValid(_rule, value, callback) {
      if (value === forgetPasswordForm.value.password) callback();
      else callback(new Error("两次密码不一致"));
    }
    const isSendButtonDisabled = ref(true);
    const isNextButtonDisabled = ref(true);
    function handleForgetPassword() {
      dialogVisible.value = true;
    }
    function handleClearValidate() {
      forgetPasswordFormRef.value.clearValidate();
    }
    function handleCloseDialog() {
      dialogVisible.value = false;
      stepIndex.value = 0;
    }
    function handleChangeEmail() {
      if (EMAIL_REG.test(forgetPasswordForm.value.email)) {
        isSendButtonDisabled.value = false;
      }
    }
    const correctCaptchaCode = ref("");
    async function handleBlurCaptchaCode() {
      if (forgetPasswordForm.value.captcha_code.length !== 6) return (isNextButtonDisabled.value = true);

      const [err] = await api.captcha.emailCaptchaVerify({ method: "post", data: forgetPasswordForm.value });
      if (err) {
        forgetPasswordFormRef.value.validateField("captcha_code");
      } else {
        isNextButtonDisabled.value = false;
        correctCaptchaCode.value = forgetPasswordForm.value.captcha_code;
        await nextTick();
        forgetPasswordFormRef.value.validate();
      }
    }
    function isCaptchaValid(_rule, value, callback) {
      if (value !== correctCaptchaCode.value) {
        callback(new Error("验证码错误"));
      } else {
        callback();
      }
    }
    const countDown = ref(60);
    const initCountDown = countDown.value;
    const {
      isActive: isCountDownActive,
      pause,
      resume,
    } = useIntervalFn(
      () => {
        countDown.value--;
        if (countDown.value <= 0) {
          pause();
        }
      },
      1000,
      { immediate: false }
    );
    async function handleSendCaptcha() {
      if (isCountDownActive.value || isSendButtonDisabled.value) return;

      const [err] = await api.captcha.emailCaptcha({ method: "post", data: { email: forgetPasswordForm.value.email } });
      if (err) {
        return console.error(err);
      }
      countDown.value = initCountDown;
      resume();
      proxy.$message.success("验证码已发送");
    }
    function handleNextStep() {
      stepIndex.value = 1;
    }
    async function handleResetPassword() {
      const [validateErr] = await to(forgetPasswordFormRef.value.validate());
      if (validateErr === false) return;
      const [err] = await api.captcha.emailResetPassword({
        method: "post",
        data: { ...omit(forgetPasswordForm.value, ["password_confirm"]), password: loginEncrypt(forgetPasswordForm.value.password) },
      });
      if (err) return;
      handleCloseDialog();
      proxy.$message.success("密码重置成功");
    }

    function initLoginForm() {
      let _loginForm = JSON.parse(decrypt(localStorage.getItem("loginForm")));
      if (_loginForm) {
        loginForm.value = _loginForm.loginForm;
        isRemember.value = _loginForm.isRemember;
      }
    }
    async function ssoLogin() {
      const { ssoToken } = proxy.$router.currentRoute.query;
      proxy.$router.replace({ query: omit(proxy.$router.currentRoute.query, "ssoToken") });
      if (ssoToken) {
        const [err] = await to(proxy.$store.dispatch("user/ssoLogin", ssoToken));
        if (err) return;
        proxy.$router.push({ path: redirect.value || "/" });
      }
    }
    onBeforeMount(() => {
      ssoLogin();
      initLoginForm();
    });

    return {
      loginRules,
      loginForm,
      isRemember,
      loading,
      passwordType,
      passwordTypeForgetPassword,
      redirect,
      cacheAccountAndPassword,
      showPwd,
      showPwdForgetPassword,
      handleLogin,
      handleForgetPassword,
      handleClearValidate,
      countDown,
      isCountDownActive,

      dialogVisible,
      stepIndex,
      forgetPasswordRules,
      forgetPasswordForm,
      forgetPasswordFormRef,
      handleCloseDialog,
      isSendButtonDisabled,
      isNextButtonDisabled,
      handleChangeEmail,
      handleBlurCaptchaCode,
      handleSendCaptcha,
      handleNextStep,
      handleResetPassword,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #fff;
$light_gray: #000;
$dark_gray: #889aa4;
$cursor: #000;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    // height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      // padding: 12px 5px 12px 15px;
      color: $light_gray;
      // height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    // background: rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 5px;
    color: #454545;
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
.forget-container {
  .el-input {
    display: inline-block;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #000;

.login-container {
  background-image: url("../../assets/bg.svg");
  background-size: 100%;
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 13vh;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
