<template>
  <div class="page-padding">
    <div class="page-header--wrap">
      <div class="page-header-wrap--title">账号管理</div>
      <div>
        <el-button icon="el-icon-plus" type="primary" @click="handleAddStaff"> 新增账号 </el-button>
      </div>
    </div>

    <el-row class="page-filter--wrap">
      <el-form inline label-width="auto">
        <el-form-item label="用户查询">
          <div class="flex">
            <el-select v-model="searchKeyname">
              <el-option v-for="item in searchKeynameList" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
            <el-input v-model="searchValue" placeholder="请输入内容" class="ml-[10px]"></el-input>
          </div>
        </el-form-item>
        <el-form-item style="margin-left: 20px">
          <el-button round icon="el-icon-refresh" @click="resetFormData"> 重置 </el-button>
          <el-button type="primary" round icon="el-icon-search" @click="handleSearch"> 搜索 </el-button>
        </el-form-item>
      </el-form>
    </el-row>

    <el-table v-loading="listLoading" :data="staffData" stripe border highlight-current-row style="width: 100%">
      <el-table-column type="index" align="center" width="60">
        <template slot="header"> 序号 </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleUpdateStaff(row)"> 编辑 </el-button>
          <el-button type="text" class="!text-red-500" @click="handleDeleteStaff(row)"> 删除 </el-button>
          <el-button type="text" @click="handleUpdateAuthority(row)"> 权限 </el-button>
        </template>
      </el-table-column>
      <el-table-column :formatter="tableFormatter" align="center" prop="account_name" label="账号名" show-overflow-tooltip />
      <el-table-column :formatter="tableFormatter" align="center" prop="user_name" label="用户名" show-overflow-tooltip />
      <el-table-column :formatter="tableFormatter" align="center" prop="phone" label="手机号" show-overflow-tooltip />
      <el-table-column :formatter="tableFormatter" align="center" prop="mail" label="邮箱" show-overflow-tooltip />
      <el-table-column align="center" label="状态">
        <template slot-scope="{ row }">
          <el-tag :type="row.status === 1 ? '' : 'danger'"> {{ row.status === 1 ? "启用" : "禁用" }} </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="角色">
        <template slot-scope="{ row }">
          {{ row.roles.map((item) => item.role_name).join(", ") || "-" }}
        </template>
      </el-table-column>
      <el-table-column :formatter="tableFormatter" align="center" prop="update_user" label="创建人/修改人" show-overflow-tooltip>
        <template slot-scope="{ row }"> {{ row.create_user || "-" }}/{{ row.update_user || "-" }} </template>
      </el-table-column>
      <el-table-column width="300" :formatter="tableFormatter" align="center" prop="update_time" label="创建时间/修改时间" show-overflow-tooltip>
        <template slot-scope="{ row }"> {{ row.create_time || "-" }}/{{ row.update_time || "-" }} </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />

    <!-- 新增账号的弹窗 -->
    <el-dialog :title="`${textMap[dialogStatus]}账号`" :visible.sync="dialogStaffForm" width="500px" @opened="handleDialogOpen">
      <el-form ref="staffForm" :rules="staffFormRules" :model="staffForm" label-width="90px">
        <el-form-item label="账号名" prop="account_name">
          <el-input v-model="staffForm.account_name" :disabled="dialogStatus === 'update'" placeholder="仅限输入数字和字母" />
        </el-form-item>
        <el-form-item label="用户名" prop="user_name">
          <el-input v-model="staffForm.user_name" />
        </el-form-item>
        <el-form-item label="邮箱" prop="mail">
          <el-input v-model="staffForm.mail" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="staffForm.phone" />
        </el-form-item>
        <el-form-item
          v-if="dialogStatus !== 'update'"
          label="密码"
          prop="password"
          :rules="[
            { required: dialogStatus !== 'update', message: '请输入密码', trigger: 'blur' },
            { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/, message: '请输入6-20位字符, 大/小写字母+数字组合', trigger: 'blur' },
          ]"
        >
          <el-input v-model="staffForm.password" placeholder="请输入6-20位字符, 大/小写字母+数字组合" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="staffForm.status">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="text" @click="dialogStaffForm = false"> 取消 </el-button>
        <el-button type="primary" @keydown.enter="handleDialogConfirm" @click="handleDialogConfirm">
          {{ dialogStatus === "update" ? "修改" : "确定" }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑权限的弹窗 -->
    <el-dialog title="编辑权限" :visible.sync="dialogAuthority">
      <el-form ref="authorityForm" :rules="authorityFormRules" :model="authorityForm" label-width="100px">
        <el-form-item label="店铺权限" prop="mall_ids">
          <el-checkbox-group v-model="authorityForm.mall_ids">
            <el-checkbox v-for="item in shopList" :key="item.id" :label="item.id">
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="角色" prop="role_ids">
          <el-select v-model="authorityForm.role_ids" placeholder="请选择角色" multiple>
            <el-option v-for="item in roleList" :key="item.id" :label="item.role_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="dialog-footer">
        <el-button type="text" @click="dialogAuthority = false"> 取消 </el-button>
        <el-button type="primary" @click="handleAuthConfirm"> 确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pagination from "@/components/Pagination";
import TableListMixin from "@/mixin/TableList";
import api from "@/api";
import { commonUtil } from "@/utils/index";
import * as R from "ramda";
import { loginEncrypt } from "@/utils/js-encrypt";
import { pick } from "lodash";
import { cloneDeep } from "lodash";

const { to } = commonUtil;
const initSearchForm = {
  account_name: "",
  user_name: "",
  phone: "",
  mail: "",
};
const initStaffForm = {
  sys_user_id: undefined,
  account_name: "",
  user_name: "",
  mail: "",
  phone: "",
  password: "",
  status: 1,
};
const initAuthorityForm = {
  id: undefined,
  mall_ids: [],
  role_ids: [],
};

function arrayNotEmptyFactory(msg) {
  return (_, value, callback) => {
    if (Array.isArray(value) && value.length > 0) {
      callback();
    } else {
      callback(new Error(msg));
    }
  };
}
const authorityFormRules = {
  mall_ids: [{ required: true, validator: arrayNotEmptyFactory("请选择店铺权限"), trigger: "change" }],
  role_ids: [{ required: true, validator: arrayNotEmptyFactory("请选择角色"), trigger: "change" }],
};
export default {
  name: "StaffManage",
  components: { Pagination },
  mixins: [TableListMixin],
  data() {
    return {
      shopList: [],
      roleList: [],
      dialogAuthority: false,
      searchKeyname: "account_name", // 搜索字段
      searchValue: "", // 搜索值
      searchKeynameList: [
        { value: "account_name", label: "账号名" },
        { value: "phone", label: "手机号" },
        { value: "mail", label: "邮箱" },
        { value: "user_name", label: "用户名" },
      ],

      listLoading: false,
      searchForm: cloneDeep(initSearchForm),
      staffData: [],
      staffForm: cloneDeep(initStaffForm),
      authorityForm: cloneDeep(initAuthorityForm),
      staffFormRules: {
        account_name: [
          { required: true, message: "请输入账号名", trigger: "blur" },
          {
            validator: (_rule, value, callback) => {
              if (this.dialogStatus === "create") {
                if (!/^[a-zA-Z0-9]+$/.test(value)) {
                  callback(new Error("只能输入数字和字母"));
                }
              }
              callback();
            },
            trigger: "blur",
          }, // 新增时才校验
        ],
        user_name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        phone: [{ pattern: /^1[3-9][0-9]{9}$/, message: "请输入正确的手机号", trigger: "blur" }],
        mail: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: "请输入正确的邮箱", trigger: "blur" },
        ],
      },
      authorityFormRules,
      dialogStaffForm: false,
    };
  },
  computed: {
    ...mapState("user", {
      related_malls: (state) => state.auth_info.related_malls,
    }),
  },
  created() {
    this.getList();
    this.getShopList();
    this.getRoleList();
  },
  methods: {
    tableFormatter(_, __, value) {
      if (!value) return "-";
      return value;
    },
    async getShopList() {
      const [, shopList] = await api.malls.allMalls();
      this.shopList = shopList;
    },
    async getRoleList() {
      const [, roleList] = await api.role.allRoles();
      this.roleList = roleList;
    },
    handleUpdateAuthority(row) {
      this.authorityForm = pick({ ...row, role_ids: row.roles.map((item) => item.role_id) }, Object.keys(initAuthorityForm));
      this.dialogAuthority = true;
      this.$nextTick(() => {
        this.$refs.authorityForm.clearValidate();
      });
    },
    resetFormData() {
      this.resetPage();
      this.searchKeyname = "account_name";
      this.searchValue = "";
      this.searchForm = R.clone(initSearchForm);
      this.getList();
    },
    handleSearch() {
      this.resetPage();
      this.getList();
    },
    handleUpdateStaff(row) {
      this.staffForm = pick({ ...row, sys_user_id: row.id }, Object.keys(initStaffForm));
      this.dialogStatus = "update";
      this.dialogStaffForm = true;
    },
    handleDeleteStaff(row) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await api.users.delete({
          method: "delete",
          id: row.id,
        });
        this.$notify.success("删除成功");
        await this.getList();
      });
    },
    resetPage() {
      this.listQuery.page = 1;
    },
    resetForm() {
      this.staffForm = cloneDeep(initStaffForm);
    },
    handleAddStaff() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogStaffForm = true;
      this.$nextTick(() => {
        this.$refs.staffForm.clearValidate();
      });
    },
    async getList() {
      // 获取数据
      this.formatSearchForm();
      const [, res] = await this.getListMixin(({ params }) => api.users.page({ method: "post", data: params }));
      this.staffData = res?.list ?? [];
    },
    formatSearchForm() {
      Object.keys(initSearchForm).forEach((key) => {
        this.searchForm[key] = "";
        if (this.searchKeyname === key) this.searchForm[key] = this.searchValue;
      });
    },
    handleDialogOpen() {
      this.$nextTick(() => {
        this.$refs.staffForm.clearValidate();
      });
    },

    // 创建和修改 HTTP 请求
    async handleDialogConfirm() {
      console.log(this.staffForm);
      const [err] = await to(this.$refs.staffForm.validate());
      console.log(err);
      if (err === false) return;
      const encryptData = { ...this.staffForm, password: loginEncrypt(this.staffForm.password) };

      const method = this.dialogStatus === "create" ? "create" : "update";
      const [err1] = await api.users[method]({
        method: "post",
        data: R.omit(this.dialogStatus === "create" ? [] : ["account_name"], encryptData),
      });
      if (err1) return;
      this.dialogStaffForm = false;
      this.$notify.success("操作成功");
      await this.getList();
    },
    async handleAuthConfirm() {
      const [err] = await to(this.$refs.authorityForm.validate());
      if (err === false) return;

      const [err2] = await api.users.updateAuth({
        method: "post",
        data: this.authorityForm,
      });
      if (err2) return;

      this.dialogAuthority = false;
      this.$notify.success("操作成功");
      await this.getList();
    },
  },
};
</script>

<style></style>

<style lang="scss" scoped></style>
