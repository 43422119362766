import { getToken } from "@/utils/auth"; // get token from cookie
import getPageTitle from "@/utils/get-page-title";
// import { Message } from "element-ui";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import router from "./router";
import store from "./store";
import { formatRoutes } from "@/store/modules/permission";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

// const whiteList = ["/login", "/dishes"]; // no redirect whitelist
const whiteList = ["/login", "/auto-login"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();
  // const hasToken = "fake token";

  if (hasToken) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      NProgress.done();
    } else {
      const hasGetUserInfo = store.getters.name;
      // const hasGetUserInfo = "fake name";
      if (hasGetUserInfo) {
        next();
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          // const { roles } = await store.dispatch("user/getInfo");
          const [, auth_info] = await store.dispatch("user/getInfo");
          const {
            menu_list = [],
            user_info: { account },
          } = auth_info;
          // 写死一个账号的需求，米良专用
          if (account === "13380084311") auth_info.roles = ["guest"];
          // generate accessible routes map based on roles
          /** 放开下面这句注释则使用本地的路由，不使用后端返回的路由 */
          // const routes = await store.dispatch("permission/generateRoutes", auth_info.roles ?? ["admin"]);
          menu_list.push({ path: "*", redirect: "/404", hidden: true });
          const routes = formatRoutes(menu_list);
          await store.dispatch("permission/generateRoutesV2", routes);
          // console.log(await routes[1].children[0].component(), "@@@");
          // dynamically add accessible routes
          // router.addRoutes(accessRoutes);
          router.addRoutes(routes);
          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true });
        } catch (error) {
          console.log(error);
          // remove token and go to login page to re-login
          await store.dispatch("user/resetToken");
          // Message.error({
          //   message: error || "Has Error",
          // });
          // Message.error(error || "Has Error");
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next({ path: "/login", query: { redirect: to.path, ...(to.query || {}) } });
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
