import { commonUtil } from "@/utils/index";
import request from "@/utils/request";

const { to } = commonUtil;

const modules = {
  goods: {
    pageGoods: "/pageGoods", // 分页获取商品
    goods: "/goods", // 获取（deprecated），创建，更新商品
    all: "/goods/all", // 多语言-获取所有商品
    detail: "/goods/detail", // 获取商品详情
    delete: "/goods/good/delete", // 批量删除商品
    moveLocation: "/goods/moveLocation", // 改变商品顺序
    batchPutOnOffSale: "/goods/batchPutOnOffSale", // 批量上下架商品

    // coupons: "/coupons", // 优惠券
    pageCoupons: "/member/pageCoupons",

    goodsTags: "/goodsTags", // 商品标签
    goodsTagsAll: "/goodsTags/all", // 获取所有商品标签和分类
    goodsTagsMove: "/goodsTags/moveLocation", // 商品标签移动

    notSupportedDeliveryAreas: "/notSupportedDeliveryAreas", // 配置不支持配送区域

    remark: "/spu_remark/remark", // 留言反馈
  },
  goodsClassification: {
    page: "/goodsClassification/page", // 分页获取商品分类
    create: "/goodsClassification/create",
    update: "/goodsClassification/update",
    updateShowMinipg: "/goodsClassification/showInMinipg", // 更新小程序是否展示按钮
    getOnSaleGoodCount: "/goodsClassification/getOnSaleGoodCount", // 获取某个分类上架商品数量 goodsClassificationId
    delete: "/goodsClassification", // id
    move: "/goodsClassification/moveLocation",
  },
  activity: {
    activity: "/adjustPriceActivities", // 获取列表get，创建或更新限时活动post // {id}/enable body: true/false
    changeValidStatus: "/activity/updateIsOnOff", // 更新活动上下架状态 // resource: enable
    searchGoods: "/adjustPriceActivities/searchGoods", // 搜索商品
  },
  auth: {
    getQiNiuToken: "/qiniuClientUploadToken", // 获取七牛token
  },
  advertisement: {
    banners: "/banners", // 广告位
    moveLocation: "/banners/moveLocation", // 改变广告位顺序

    bannerTags: "/bannerPositions", // 广告位标签
  },
  customerService: {
    customerService: "/customerService",
    history: "/customerService/history",

    historyTable: "/customerService/historyList",
    forwardImage: "/fileFoward/forwardImage", // 转发图片接口，解决防盗链问题 GET ?url=imageUrl

    keywords: "/customerServiceResponseText", // resource: add|update
    endResponseText: "/customerServiceResponseText/findFinish", // 结束语
    updateEndResponseText: "/customerServiceResponseText/updateFinish",
    defaultResponseText: "/customerServiceResponseText/findWelcomeSpeech", // 欢迎语
    updateDefaultResponseText: "/customerServiceResponseText/updateWelcome",
  },
  logistics: {
    logisticsCompanies: { url: "/logisticsCompanies", cache: true },
  },
  merchantShop: {
    merchantShop: "/merchantShop",
  },
  malls: {
    allMalls: "/allMalls",
    malls: "/malls",
    dailyReport: "/trmall/list", // 太二商城全店日报
    downloadDailyReport: "/trmall/downloadDailyReport",
    productEffectReport: "/trmall/productEffectList", // 太二商城商品效果查询
    downloadProductEffectReport: "/trmall/downloadProductEffect",
    refreshDailyReport: "/trmall/fillHistoryData", // 太二商城刷新数据
    refreshGoods: "/trmall/fillHistoryGoodsStatistic", // 太二商城刷新数据
  },
  order: {
    orders: "/orders",
    batchUpdateStatus: "/orders/batchUpdateStatus", // 批量操作订单状态
    afterSaleTickets: "/afterSaleTickets", // 售后
    batchProcessAfterSaleRequest: "/afterSaleTickets/batchProcessAfterSaleRequest", // 批量处理售后
    downloadAfterSaleExcel: "/afterSaleTickets/downloadExcel",
    pushOrderLinesToQidebao: "/orderLine/pushOrderLinesToQidebao", // 推送orderLines到企得宝
    batchForwardOrderLineDeliveryStatus: "/orders/batchForwardOrderLineDeliveryStatus", // 批量前进orderLine的配送状态
    setOrderLinesDeliverySheetInfo: "/orderLine/setOrderLinesDeliverySheetInfo", // 人工设置orderLines的配送单
    deliverySheetsBatchUpdate: "/deliverySheets/batchUpdate",
    deliverList: "regular_goods/listRegularOrderItemDtoByPage", // 发货管理
    regular: "regular_goods/orders", // 定时发货订单/详情
    deliverGood: "/regular_goods/expressOnline", // 开始发货
    updateRegularBlessing: "regular_goods/updateBlessing", // 修改礼品卡祝福语
    downloadOrderExcel: "/orders/downloadOrderExcel", // 导出订单文件
    downloadOrderLineExcel: "/orders/downloadOrderLineExcel", // 导出订单文件
    downloadToBeDeliverOrder: "/deliverySheets/downloadToBeDeliverOrder",
    checkToBeDeliverOrder: "/deliverySheets/checkToBeDeliverOrder",
    uploadToBeDeliverOrder: "/deliverySheets/uploadToBeDeliverOrder",
  },
  settings: {
    autoPushOrderToQidebao: "/autoPushOrderToQidebao", // 推送企得宝设置
    returnGoodsInfo: "/returnGoodsInfo", // 退货设置
    transactionRelatedConfig: "/transactionRelatedConfig", // 交易相关设置
    area: { url: "/address", cache: true }, // 禁发地区
    pickUpAddress: "/pickUpAddress", // 自提地址
  },
  users: {
    ssoLogin: "/sso/exchangeToken", // sso登录
    login: "/adminUser/login", // 登录
    humanResourceMiddlePlatformLogin: "/adminUser/humanResourceMiddlePlatformLogin", // 人力中台登录
    logout: "/adminUser/logout", // 登出
    info: "/adminUser/info", // 获取信息
    adminUsers: "/adminUsers", // 用户管理
    page: "/adminUser/page", // 用户管理
    updateAuth: "/adminUser/updateAuth", // 更新用户权限
    switchMall: "/adminUser/switchMall", // 切换商城
    create: "/adminUser/create",
    update: "/adminUser/update",
    delete: "/adminUser/delete", // {sysUserId}
  },
  role: {
    add: "/role/add", // 新增角色
    update: "/role/update",
    delete: "/role/delete",
    updateStatus: "/role/updateStatus",
    page: "/role/page",
    allRoles: "/role/all",
  },
  menu: {
    fetchAll: "/menu/all", // 获取所有菜单
  },
  member: {
    users: "/users",
    privacyPolicyHistory: "/users/page/privacyPolicyHistory",
    listGrade: "/member/listGrade",
    listBrand: "/member/listBrand",
  },
  download: {
    cdKey: "regular_goods/downCdKey", // 激活码
    regularOrder: "regular_goods/downloadRegularOrder", // 定时发货order
    regularOrderDetail: "regular_goods/downloadRegularOrderDetail", // 发货管理order
  },
  booking: {
    reserveList: "/chef_studio/chefStudioList",
    reserveSetting: "/chef_studio",
    updateLimitSetting: "/chef_studio/updateLimitTime",
    orderList: "/chef_studio/chefStudioOrderList",
    refund: "/chef_studio/refund",
  },
  multiLanguage: {
    getAllLanguage: "/multiLanguage/all",
    setting: "/multiLanguage/setting",
    getSetting: "/multiLanguage/already/setting",
    goodInfo: "/multiLanguage/good/info",
    updateGoodTranslation: "/multiLanguage/translation",
    publish: "/multiLanguage/publish",
  },
  captcha: {
    emailCaptcha: "/captcha/email",
    emailCaptchaVerify: "/captcha/email/check",
    emailResetPassword: "/captcha/reset/password",
  },
};
let apiAction = {};
Object.keys(modules).forEach((modulesName) => {
  apiAction[modulesName] = {};
  Object.keys(modules[modulesName]).forEach((apiName) => {
    apiAction[modulesName][apiName] = async ({ method = "get", id, resource, ...options } = {}) => {
      let [url, extraOptions] = getUrlAndOptions(modules[modulesName][apiName]);
      url += `${id !== undefined ? `/${id}` : ""}${resource ? `/${resource}` : ""}`;
      return await to(request({ url, method, ...extraOptions, ...options }));
    };
  });
});

function getUrlAndOptions(moduleValue) {
  if (typeof moduleValue === "string") return [moduleValue, {}];
  else {
    const { url, ...options } = moduleValue;
    return [url, options];
  }
}

export default apiAction;
