var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "upload-wrap",
    },
    [
      _c(
        "div",
        {
          staticClass: "upload-list",
        },
        [
          _c(
            "draggable",
            {
              staticClass: "draggable-list",
              attrs: {
                options: {
                  animation: 150,
                  ghostClass: "ghost",
                },
              },
              on: {
                end: _vm.handleDragEnd,
                start: _vm.handleDragStart,
              },
              model: {
                value: _vm.fileList,
                callback: function callback($$v) {
                  _vm.fileList = $$v
                },
                expression: "fileList",
              },
            },
            _vm._l(_vm.fileList, function (file, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "upload-item",
                },
                [
                  _c("img", {
                    staticClass: "upload-image",
                    attrs: {
                      src: file.url,
                      alt: "",
                    },
                    on: {
                      click: function click($event) {
                        return _vm.picCardPreview(file)
                      },
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "upload-actions",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "upload-action-item",
                          on: {
                            click: function click($event) {
                              return _vm.picCardPreview(file)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-zoom-in",
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "upload-action-item",
                          on: {
                            click: function click($event) {
                              return _vm.handleReplace(file)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-sort rotate-90",
                          }),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "upload-action-item",
                          on: {
                            click: function click($event) {
                              return _vm.handleSlotRemove(file)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-delete",
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm.showAllButtons()
                    ? [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleSetCoverImage(file)
                              },
                            },
                          },
                          [_vm._v(" 设置为封面图 ")]
                        ),
                      ]
                    : [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.isButtonDisabled(file),
                              type: "text",
                            },
                            on: {
                              click: function click($event) {
                                return _vm.handleSetCoverImage()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isButtonDisabled(file)
                                    ? "设置为封面图"
                                    : "取消设置为封面图"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                ],
                2
              )
            }),
            0
          ),
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-container-ref",
              class: {
                "hide-upload-holder": _vm.hideUpload,
              },
              attrs: {
                action: _vm.upload_qiniu_area,
                "auto-upload": true,
                limit: _vm.limitCount,
                multiple: true,
                accept: _vm.accept,
                "file-list": [],
                "list-type": "picture-card",
                "on-preview": _vm.picCardPreview,
                "before-upload": _vm.beforePicUpload,
                "on-exceed": _vm.handleExceed,
                "on-remove": _vm.removePic,
                "http-request": _vm.uploadQiniu,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "el-icon-plus",
                  staticStyle: {
                    color: "#409eff",
                  },
                  attrs: {
                    slot: "default",
                  },
                  slot: "default",
                },
                [_vm._v("添加图片")]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-upload",
        {
          ref: "replaceUpload",
          staticClass: "hide",
          attrs: {
            action: _vm.upload_qiniu_area,
            "auto-upload": true,
            "show-file-list": false,
            accept: _vm.accept,
            "before-upload": _vm.beforePicUpload,
            "http-request": _vm.handleReplaceUpload,
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: {
                display: "none",
              },
              attrs: {
                slot: "trigger",
              },
              slot: "trigger",
            },
            [_vm._v("选取文件")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            modal: false,
          },
          on: {
            "update:visible": function updateVisible($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              width: "100%",
              src: _vm.dialogImageUrl,
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }